/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React from "react"

const dotSize = "10px"

const BrowserThumb = ({ background, width = "400px", height = "250px" }) => (
  <div
    css={css`
      position: relative;
      width: ${width};
      border-radius: 0.5rem;
      background-color: #fff;
      margin: 0 auto;
      -webkit-box-shadow: -2px 0px 20px 5px rgba(209, 209, 209, 0.3);
      -moz-box-shadow: -2px 0px 20px 5px rgba(209, 209, 209, 0.3);
      box-shadow: -2px 0px 20px 5px rgba(209, 209, 209, 0.3);
      margin-bottom: 60px;
      @media (max-width: 768px) {
        width: 90%;
      }
    `}
  >
    <div
      css={css`
        position: absolute;
        width: ${dotSize};
        height: ${dotSize};
        background-color: #ececec;
        border-radius: 20px;
        top: 10px;
        left: 15px;
      `}
    />
    <div
      css={css`
        position: absolute;
        width: ${dotSize};
        height: ${dotSize};
        background-color: #ececec;
        border-radius: 20px;
        top: 10px;
        left: 30px;
      `}
    />
    <div
      css={css`
        position: absolute;
        width: ${dotSize};
        height: ${dotSize};
        background-color: #ececec;
        border-radius: 20px;
        top: 10px;
        left: 45px;
      `}
    />
    <div
      css={css`
        position: relative;
        width: 100%;
        height: ${height};
        background-image: url(${background});
        background-size: cover;
        background-position: top center;
        border-radius: 0 0 0.5rem 0.5rem;
        top: 30px;
        background-color: #ccc;
        @media (max-width: 768px) {
          height: 275px;
        }
      `}
    />
  </div>
)

export default BrowserThumb
