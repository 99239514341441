/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React from "react"
import { Container, Row, Col } from "reactstrap"
import AngleCover from "./angleCover"

const Blurb = ({
  image,
  attribution,
  body,
  bgcolor1 = "#f1f1f1",
  bgcolor2 = bgcolor1,
  rotation,
  bottomAngleMargin,
  topMargin,
}) => (
  <React.Fragment>
    <AngleCover rotation={"3deg"} topMargin="-40px" height="150px" />
    <Container
      fluid={true}
      css={css`
        position: relative;
        padding: 20px 0;
        margin-top: -80px;
      `}
    >
      <Row>
        <Col
          className="col-12 col-lg-12"
          css={css`
            background-color: ${bgcolor1};
            min-height: 430px;
            @media (max-width: 415px) {
              min-height: 600px;
            }
          `}
        />
      </Row>
      <Row
        css={css`
          position: absolute;
          top: ${topMargin}px;
          width: 100%;
          margin: 0;
        `}
      >
        <Col
          className="col-12 col-lg-6 offset-lg-3 col-xl-6 offset-xl-3 order-2"
          css={css`
            z-index: 40;
          `}
        >
          <Container>
            <Row>
              <Col>
                <p
                  css={css`
                    font-family: Nunito;
                    font-size: 1.1rem;
                    font-weight: 400;
                  `}
                >
                  {body}
                </p>
                <p
                  css={css`
                    font-style: italic;
                    font-family: Nunito;
                    font-size: 0.9rem;
                    font-weight: 400;
                    text-align: right;
                  `}
                >
                  {attribution}
                </p>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
    <AngleCover rotation={rotation} topMargin={bottomAngleMargin} />
  </React.Fragment>
)

export default Blurb
