/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React from "react"
import BrowserThumb from "./browserThumb"
import { Container, Row, Col } from "reactstrap"
import image1 from "../assets/pp-thumb.jpg"
import image2 from "../assets/dnb-thumb.jpg"
import image3 from "../assets/phun-thumb.jpg"
import image4 from "../assets/rezi-thumb.jpg"
import image5 from "../assets/unity.png"

const CaseStudies = () => (
  <Container
    className={css`
      position: relative;
      z-index: 40;
    `}
  >
    <Row>
      <Col>
        <h2
          css={css`
            font-family: Nunito;
            margin-bottom: 40px;
            margin-top: 20px;
          `}
        >
          Featured Work
        </h2>
      </Col>
    </Row>
    <Row>
      <Col
        className="col-12 col-md-6 col-lg-3"
        css={css`
          text-align: center;
        `}
      >
        <a
          href="/portfolio/unity/"
          css={css`
            :hover {
              text-decoration: none;
            }
          `}
        >
          <BrowserThumb background={image5} width={"100%"} height={"200px"} />
          <h4
            css={css`
              font-family: Nunito;
              text-align: center;
              color: #666;
            `}
          />
        </a>
      </Col>
      <Col
        className="col-12 col-md-6 col-lg-3"
        css={css`
          text-align: center;
        `}
      >
        <a
          href="/portfolio/resideo/"
          css={css`
            :hover {
              text-decoration: none;
            }
          `}
        >
          <BrowserThumb background={image4} width={"100%"} height={"200px"} />
          <h4
            css={css`
              font-family: Nunito;
              text-align: center;
              color: #666;
            `}
          />
        </a>
      </Col>
      <Col
        className="col-12 col-md-6 col-lg-3"
        css={css`
          text-align: center;
        `}
      >
        <a
          href="/portfolio/paypal/"
          css={css`
            :hover {
              text-decoration: none;
            }
          `}
        >
          <BrowserThumb background={image1} width={"100%"} height={"200px"} />
          <h4
            css={css`
              font-family: Nunito;
              text-align: center;
              color: #666;
            `}
          />
        </a>
      </Col>
      <Col
        className="col-12 col-md-6 col-lg-3"
        css={css`
          text-align: center;
        `}
      >
        <a
          href="/portfolio/dnb/"
          css={css`
            :hover {
              text-decoration: none;
            }
          `}
        >
          <BrowserThumb background={image2} width={"100%"} height={"200px"} />
          <h4
            css={css`
              font-family: Nunito;
              text-align: center;
              color: #666;
            `}
          />
        </a>
      </Col>
      <Col
        className="col-12 col-md-6 col-lg-3"
        css={css`
          text-align: center;
        `}
      >
        <a
          href="/portfolio/phunware/"
          css={css`
            :hover {
              text-decoration: none;
            }
          `}
        >
          <BrowserThumb background={image3} width={"100%"} height={"200px"} />
          <h4
            css={css`
              font-family: Nunito;
              text-align: center;
              color: #666;
              padding-bottom: 20px;
            `}
          />
        </a>
      </Col>
    </Row>
  </Container>
)

export default CaseStudies
