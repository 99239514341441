import React from "react"
import blizzard from "../assets/thumbs/blizz-thumb.jpg"
import starboard from "../assets/thumbs/starboard-thumb.jpg"
import simpsons from "../assets/thumbs/simpsons-thumb.jpg"
import llad from "../assets/thumbs/llad-thumb1.jpg"
import q4yc from "../assets/thumbs/q4yc-thumb.jpg"
import car2go from "../assets/thumbs/car2gp-thumb.jpg"
import pertusis from "../assets/thumbs/pertusis-thumb.jpg"
import immtrac from "../assets/thumbs/immtrac-thumb.jpg"
import dmwt from "../assets/thumbs/dmwt-thumb.jpg"
import spiderman from "../assets/thumbs/spiderman-thumb.jpg"
import bag from "../assets/thumbs/grab-bag-thumb.jpg"
import wb from "../assets/thumbs/wb-thumb.jpg"
import uxImage from "../assets/user-ui.jpg"
import ThumbsRowContainer from "../components/thumbsRow"
import Layout from "../components/layout"
import Blurb from "../components/blurb"
import CaseStudies from "../components/caseStudies"
import { Container, Row, Col } from "reactstrap"
import { css } from "emotion"

const Portfolio = () => (
  <Layout headerType="portfolio" headerText="Portfolio">
    <CaseStudies />
    <Blurb
      image={uxImage}
      bgcolor1="#fcf5d9"
      attribution="Charles Fletcher, Director of Product Management at PayPal"
      body="Gus embodies the developer that I work with best. He is incredibly adept in web technology, fast prototyping and iterative, agile-driven development. A lot of people have those skills, but when you combine them with Gus's level of dedication, incredibly positive attitude, and open mindset you find you're working with someone who is operating at a completely different level."
      rotation="3deg"
      bottomAngleMargin="-60px"
      buttonText="See case study"
      topMargin="120"
    />
    <Container
      css={css`
        position: relative;
        z-index: 40;
      `}
    >
      <Row>
        <Col>
          <h2
            css={css`
              font-family: Nunito;
              margin-top: -40px;
              margin-bottom: 40px;
            `}
          >
            Portfolio Archive
          </h2>
        </Col>
      </Row>
    </Container>
    <ThumbsRowContainer
      image1={blizzard}
      image2={starboard}
      image3={simpsons}
      header="Full Stack Developement"
      body="For over 20 years I've been using various technologies on both the front end and server side of things to create exceptional products and memorable experiences for a wide array companies. I am currently a Senior Software Engineer at PayPal and work mostly with Javascript, Node and React. To see some of my previous work history check out my LinkedIn profile."
      rotation="3deg"
    />
    <ThumbsRowContainer
      image1={llad}
      image2={q4yc}
      image3={car2go}
      header="Full Stack Developement"
      body="For over 20 years I've been using various technologies on both the front end and server side of things to create exceptional products and memorable experiences for a wide array companies. I am currently a Senior Software Engineer at PayPal and work mostly with Javascript, Node and React. To see some of my previous work history check out my LinkedIn profile."
      rotation="3deg"
    />
    <ThumbsRowContainer
      image1={pertusis}
      image2={immtrac}
      image3={dmwt}
      header="Full Stack Developement"
      body="For over 20 years I've been using various technologies on both the front end and server side of things to create exceptional products and memorable experiences for a wide array companies. I am currently a Senior Software Engineer at PayPal and work mostly with Javascript, Node and React. To see some of my previous work history check out my LinkedIn profile."
      rotation="3deg"
    />
    <ThumbsRowContainer
      image1={spiderman}
      image2={bag}
      image3={wb}
      header="Full Stack Developement"
      body="For over 20 years I've been using various technologies on both the front end and server side of things to create exceptional products and memorable experiences for a wide array companies. I am currently a Senior Software Engineer at PayPal and work mostly with Javascript, Node and React. To see some of my previous work history check out my LinkedIn profile."
      rotation="3deg"
    />
  </Layout>
)

export default Portfolio
