/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React from "react"
import BrowserThumb from "./browserThumb"
import { Container, Row, Col } from "reactstrap"

const ThumbRowsContainer = ({
  image1,
  image2,
  image3,
  header,
  body,
  type = "web",
  rotation,
}) => (
  <React.Fragment>
    <Container
      css={css`
        position: relative;
        margin-top: 20px;
        z-index: 40;
      `}
    >
      <Row>
        <Col
          className="col-12 col-lg-4 col-sm-12"
          css={css`
            text-align: center;
          `}
        >
          <BrowserThumb background={image1} width={350} />
        </Col>
        <Col
          className="col-12 col-lg-4 col-sm-12"
          css={css`
            text-align: center;
          `}
        >
          <BrowserThumb background={image2} width={350} />
        </Col>
        <Col
          className="col-12 col-lg-4 col-sm-12"
          css={css`
            text-align: center;
          `}
        >
          <BrowserThumb background={image3} width={350} />
        </Col>
      </Row>
    </Container>
  </React.Fragment>
)

export default ThumbRowsContainer
